import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.initializeSelect2(this.containerTarget)
  }

  addRow(event) {
    event.preventDefault()
    const content = this.containerTarget.dataset.template.replace(/NEW_RECORD/g, new Date().getTime())
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = content
    const newContent = tempDiv.firstElementChild
    this.containerTarget.appendChild(newContent)
    this.initializeSelect2(newContent)
  }

  removeRow(event) {
    event.preventDefault()
    if (confirm('Are you sure you want to remove this setting?')) {
      const row = event.target.closest('.nested-fields')
      if (row.querySelector('input[name*="_destroy"]')) {
        row.querySelector('input[name*="_destroy"]').value = 1
        row.style.display = 'none'
      } else {
        row.remove()
      }
    }
    
  }
  initializeSelect2(element) {
    $(element).find('select.select2').each(function() {
      const $select = $(this)
      $select.select2()
      if ($select.hasClass('is-invalid')) {
        // Add class to the Select2 container to show it in red
        setTimeout(() => {
          $select.next('.select2-container').find('.select2-selection').addClass('is-invalid')
        }, 0)
      }
    })
  }
}