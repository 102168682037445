class HouseholdMembersCoDonors {
  constructor() {
    this.checkboxes = document.querySelectorAll('.custom-control-input[type="checkbox"]');
    this.hiddenField = document.getElementById('hidden_co_donor_values');
    this.form = this.hiddenField.closest('form');
    this.init();
  }

  init() {
    // Initialize event listeners on checkboxes
    this.checkboxes.forEach(checkbox => {
      checkbox.addEventListener('change', () => this.handleCheckboxChange());
    });

    // Attach an event listener to form submission
    this.form.addEventListener('submit', () => this.updateHiddenField());
  }

  handleCheckboxChange() {
    // Called when checkboxes are changed, to update hidden field before form submission
    this.updateHiddenField();
  }

  updateHiddenField() {
    // Construct the nested attributes format
    const membersAttributes = {};
    // We are not using the cnstructor variable here because if we remove a member that is checked and 
    // submit it will also send the deleted member in params, which will fail the form submission.
    document.querySelectorAll('.custom-control-input[type="checkbox"]').forEach((checkbox, index) => {
      const memberId = checkbox.dataset.memberId;
      membersAttributes[index] = { id: memberId, co_donor: checkbox.checked };
    });

    // Update the hidden field value to be a JSON string of the nested attributes
    this.hiddenField.value = JSON.stringify(membersAttributes);
  }
}

// Initialize the class when the page is loaded
$(document).on('turbolinks:load', function() {
  if ($('body.households.edit').length > 0 || $('body.households.new').length > 0) {
    new HouseholdMembersCoDonors();
  }
});
