class TextMessageForm {
  constructor(form) {
    this.form = form;
    this.contentTextarea = this.form.find('#text_message_content');
    this.maxCharactersPerMessage = 160
    this.deviceContainer = $("#device_container");
    this.imageField = form.find('#text_message_image');
    this.imagePreview = form.find('#image_preview');
    this.recipientsSelect = form.find('select#text_message_group_ids');
    this.recipientList = $('.device-preview #recipient_list');
    this.clearImageContainer = form.find('#clear_image_link');
    this.clearImageLink = this.clearImageContainer.find('a');
    this.addImageCheckbox = form.find('#add_image')
    this.addImageContainer = form.find('#add_image_custom_file')
    this.clearImageHiddenField = form.find('#text_message_clear_image');

    this.recipientsSelect.on('change.select2', event => {
      this.updateRecipientList();
    });

    this.contentTextarea.on('keyup', event => {
      this.updateRemainingCharacters();
      this.updateChatBubbles();
    });

    this.imageField.change(event => {
      const file = this.imageField[0].files[0];
      this.imagePreview.attr('src', URL.createObjectURL(file));
      this.clearImageContainer.removeClass('d-none');
      this.clearImageHiddenField.val('');
      this.updateChatBubbles();
    });

    this.clearImageLink.click(event => {
      this.clearImage();
      event.preventDefault();
    });

    this.addImageCheckbox.change(event => {
      if (this.addImageContainer.hasClass('d-none')){
        this.clearImage();
      }
      event.preventDefault();
    })

    this.updateRecipientList();
    this.updateChatBubbles();
    this.updateRemainingCharacters();
  }

  clearImage(){
    this.imageField.val('');
    this.clearImageContainer.addClass('d-none');
    this.imagePreview.attr('src', '');
    this.clearImageHiddenField.val('1');
    this.updateChatBubbles();
  }

  updateRecipientList() {
    if (this.recipientsSelect.length) {
      const selections = this.recipientsSelect.select2('data');
      if (selections.length > 0)
        this.recipientList.text(this.recipientsSelect.select2('data').map(d => d['text']).join(', '))
      else
        this.recipientList.empty()
    }
  }

  updateRemainingCharacters() {
    const helpText = this.form.find('.remaining-characters');
    const contentLength = this.contentLength();

    if (contentLength <= this.maxCharactersPerMessage) {
      const remaining = this.maxCharactersPerMessage - contentLength;
      helpText.text(remaining + ' characters remaining');
    } else {
      helpText.text(`This text will be split into ${this.messageCount()} separate messages`);
    }
  }

  updateChatBubbles() {
    const previewImageSrc = this.imagePreview.attr('src');

    this.deviceContainer.empty()

    if (!!previewImageSrc)
      this.deviceContainer.append($(`<div class='chat-bubble'><img src="${previewImageSrc}"/></div>`));

    $.each(this.messages(), (i, message) => {
      const sanitizedMessage = $('<div>').text(message).html().replace(/\n/g, '<br>');
      this.deviceContainer.append($(`<div class='chat-bubble'>${sanitizedMessage}</div>`));
    });

    $(this.deviceContainer).linkify({ target: "_blank" });
  }

  messages() {
    const content = this.content();
    const messageCount = this.messageCount()
    const messages = new Array(messageCount)

    for (let i = 0, o = 0; i < messageCount; ++i, o += this.maxCharactersPerMessage) {
      messages[i] = content.substr(o, this.maxCharactersPerMessage)
    }

    return messages
  }

  messageCount() {
    return Math.ceil(this.contentLength() / this.maxCharactersPerMessage)
  }

  content() {
   return this.contentTextarea.val()
  }

  contentLength() {
    return this.content().length
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('form#text-message-form');
  if (!(form.length > 0)) { return; }
  new TextMessageForm(form);
});
