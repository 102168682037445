// app/javascript/controllers/donor_controller.js
import { Controller } from "stimulus";

/**
 * Controller for managing the co-donors section in the donation form.
 * 
 * This controller:
 * - Listens for changes on the user select field (via Select2 events) so that it can update the co-donors list.
 * - Fetches the co-donors from the server based on the selected user.
 * - Generates checkboxes for each co-donor.
 *
 * In "new" donation mode, all co-donor checkboxes are checked by default.
 * In "edit" mode, only the co-donors that are already associated with the donation are checked.
 */
export default class extends Controller {
  // Define the targets for this controller.
  // "userSelect" is the user dropdown element.
  // "coDonors" is the container where the co-donors checkboxes will be rendered.
  static targets = ["userSelect", "coDonors"]

  /**
   * Connect lifecycle method.
   * Called automatically when the controller is instantiated.
   */
  connect() {
    // Bind the change event on the user select field to ensure that when a new user is selected,
    // the co-donors list is updated.
    this.bindSelect2ChangeEvent();
    // Immediately update the co-donors list when the controller is connected.
    this.updateCoDonors();
  }

  /**
   * Binds a change event to the user select field (using the Select2 plugin).
   * When an option is selected, a native 'change' event is dispatched so that the co-donors list can be updated.
   */
  bindSelect2ChangeEvent() {
    // Use jQuery to attach the event handler on the element with ID "donation_user_id".
    $("#donation_user_id").on('select2:select', function () {
      // Create a native change event that bubbles.
      let event = new Event('change', { bubbles: true });
      // Dispatch the event on the current element.
      this.dispatchEvent(event);
    });
  }

  /**
   * Updates the co-donors list by fetching data from the server.
   * 
   * The method first determines the user ID to use, either from the userSelect target (if present)
   * or from a fallback data attribute on the container element.
   * It then makes an AJAX request to fetch co-donors and renders checkboxes for each.
   */
  updateCoDonors() {
    // Get the user ID from the userSelect element.
    const userId = this.userSelectTarget.value;
    // Get the donation ID from the userSelect element's data attributes.
    const donationId = this.userSelectTarget.dataset.donation;
    // Reference the element where co-donors checkboxes will be rendered.
    const targetElement = this.coDonorsTarget;

    // Clear out any old content.
    targetElement.innerHTML = "";

    if (userId) {
      // Fetch the co-donors from the server for this user and donation.
      fetch(`/users/${userId}/co_donors?donation_id=${donationId}`)
        .then(response => response.json())
        .then(data => {
          // Parse the submitted co_donor IDs from the data attribute.
          // This should be a JSON-encoded array; if it's not set, default to an empty array.
          let submittedIDs = [];
          try {
            submittedIDs = JSON.parse(this.coDonorsTarget.dataset.checkedCoDonors || "[]").map(Number);
          } catch (e) {
            console.error("Error parsing checked_co_donors data attribute:", e);
            submittedIDs = [];
          }

          // Extract the IDs from the fetched data.
          const fetchedIDs = data.map(coDonor => coDonor.id);

          // Determine which submitted co-donor IDs are missing from the fetched data.
          // For each missing ID, create a placeholder coDonor object.
          const missingCoDonors = submittedIDs
            .filter(id => !fetchedIDs.includes(id))
            .map(id => ({ id: id, name: `User #${id}` }));

          // Merge the fetched co-donors with the missing ones.
          const allCoDonors = data.concat(missingCoDonors);

          // If we have any co-donors to display, render them.
          if (allCoDonors.length > 0) {
            targetElement.innerHTML = '<label for="co_donors"><span class="required">*</span> Co-Donor</label>';
            targetElement.innerHTML += allCoDonors.map(coDonor => this.generateCheckbox(coDonor, donationId)).join('');
          }
        })
        .catch(error => {
          console.error("There was an error fetching the co-donors!", error);
        });
    }
  }


  /**
   * Generates an HTML string for a checkbox input for a given co-donor.
   * 
   * The checkbox will be checked based on the current action:
   * - In "new" or "create" mode, all checkboxes are checked by default.
   * - In "edit" or "update" mode, only checkboxes for co-donors whose IDs are included
   *   in the donation's co_donor_ids will be checked.
   *
   * @param {Object} coDonor - The co-donor object returned from the server.
   * @param {String|Number} donationId - The ID of the donation.
   * @returns {String} The HTML for the checkbox.
   */
  generateCheckbox(coDonor, donationId) {
    // Retrieve the JSON-encoded string of checked co-donor IDs.
    // Ensure a valid JSON string by defaulting to "[]" if missing.
    const checkedCoDonorsData = this.coDonorsTarget.dataset.checkedCoDonors || "[]";
    
    // Parse the JSON string to get an array of co-donor IDs.
    let parsedCheckedCoDonors = [];
    try {
      parsedCheckedCoDonors = JSON.parse(checkedCoDonorsData);
    } catch (e) {
      // If parsing fails, default to an empty array.
      parsedCheckedCoDonors = [];
    }
    // Convert all IDs to numbers for accurate comparison.
    parsedCheckedCoDonors = parsedCheckedCoDonors.map(Number);
    
    // Get the current action from the data attribute.
    // This is expected to be 'new'/'create' for a new donation or 'edit'/'update' for an existing one.
    const currentAction = this.coDonorsTarget.dataset.action;
    
    let isChecked = false;
    // If there are submitted co-donor IDs, use those to determine which checkbox should be checked.
    if (parsedCheckedCoDonors.length > 0) {
      isChecked = parsedCheckedCoDonors.includes(coDonor.id);
    } else {
      // If no submitted values exist, determine default based on the form's mode:
      if (currentAction === 'new' || currentAction === 'create') {
        // For new donations, default all checkboxes to checked.
        isChecked = true;
      } else if (currentAction === 'edit' || currentAction === 'update') {
        // For editing, default to unchecked if no submitted value is present.
        isChecked = false;
      }
    }
    
    // Return the HTML string for the checkbox.
    return `
      <div class="custom-control custom-checkbox">
        <input type="checkbox" name="donation[co_donor_ids][]" value="${coDonor.id}" class="custom-control-input" id="co_donor_${coDonor.id}" ${isChecked ? 'checked' : ''}>
        <label class="custom-control-label" for="co_donor_${coDonor.id}">${coDonor.name}</label>
      </div>
    `;
  }

}
